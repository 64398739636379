@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

* {
    font-family: 'Satoshi-Regular', ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

b {
    font-family: 'Satoshi-Bold', ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h2, h2 * {
    font-family: 'Gothic-Regular', ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.uploader p.text-secondary.mb-0 {
    visibility: hidden;
}
.uploader p.text-secondary.mb-0:after {
    content: "Glissez / Déposez un fichier";
    display: block;
    visibility: visible;
    text-align: center;
}

.uploader label.btn.btn--file.btn--primary.btn--upload {
    background-color: #E0A0C8;
    border: none;
}

.uploader label.btn.btn--file:not(.btn--upload) {
    visibility: hidden;
}

.uploader label.btn.btn--file:not(.btn--upload):after {
    content: "Ajouter un autre fichier";
    display: block;
    visibility: visible;
    text-align: center;
}